<template>
  <div class="exam-list">
    <el-button type="primary" size="medium" class="new-btn" @click="addExam">新建考试</el-button>
    <div class="tab">
      <div v-for="(item, index) in tab" :key="index" :class="{ 'active': index == activeIndex }"
        @click="changeType(index)">
        <i class="iconfont icon-fuzhi" :class="[index == 0 ? 'icon-fuzhi' : 'icon-kaoshi']"></i>
        {{ item.name }}
      </div>
    </div>
    <ul class="list">
      <li v-for="(item, index) in examList" :key="index">
        <div class="top">
          <div class="left">
            <!-- 1正式考试 2模拟考试 -->
            <span class="type" :style="{'background': item.type == 2?'#f59a23':'#3bafda'}">{{ item.type == 2 ? '模拟考试' : '正式考试' }}</span>
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="right" v-if="activeIndex == 0">
            <a href="javascript:;" @click="importStudent(item)">导入考生</a>
            <a href="javascript:;" @click="edit(item)">编辑</a>
            <a href="javascript:;" @click="del(item)">删除</a>
          </div>
          <div class="right" v-if="activeIndex == 1">
            <a href="javascript:;" @click="download(item)">下载答题明细</a>
          </div>
        </div>
        <div class="top">
          <div class="left">
            <i class="iconfont icon-ruanjian-"></i>
            <span>考试时间:{{ item.startDttm }}到{{ item.endDttm }}</span>
          </div>
          <div class="right">
            <a href="javascript:;" @click="view(item)">查看结果</a>
          </div>
        </div>
      </li>
    </ul>
    <el-empty description="暂无数据" v-if="total == 0"></el-empty>
    <div class="pagination" v-if="total > 0">
      <el-pagination background layout="prev, pager, next" :total="total" @size-change="sizeChange()"
        @current-change="currentChange" :current-page="current" :page-size="pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ApisConfig from '@/apis/api.config';
const prefix = ApisConfig.examApi + '/exam/web/';
export default {
  name: 'ExamList',
  data() {
    return {
      user: {},
      tab: [{ name: '考试任务' }, { name: '考试历史' }],
      activeIndex: 0,
      examList: [],
      total: 0,
      current: 1,
      pageSize: 5,
    };
  },
  components: {},
  created() {
    setTimeout(()=>{
      console.log("🚀 ~ created ~ this.$store.state.historyRoute:", this.$store.state.historyRoute)
    },2000)

    this.getList();
  },
  methods: {
    go(index, item) {
      this.activeIndex = index;
      this.$router.push(item.route);
    },
    async getList() {
      // 0: 未结束，1: 已结束
      let params = {
        state: this.activeIndex,
        page: {
          current: this.current,
          size: this.pageSize,
        },
      };
      const result = await this.Api.exammanage.queryExamPage(params);
      this.examList = result.data.records;
      this.total = result.data.total;
    },
    // 新增考试
    async addExam() {
      this.$router.push('/admin/exam-manage/add-exam/-1');

      // const result = await this.Api.exammanage.addExam({});
    },
    changeType(index) {
      if (this.activeIndex == index) return;
      this.activeIndex = index;
      this.current = 1;
      this.getList();
    },
    sizeChange() { },
    currentChange(page) {
      this.current = page;
      this.getList();
    },
    downloadFile(file,fileName) {
      const blob = new Blob([file]);
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    },

    async download(item) {
      const res = await this.Api.exammanage.exportExamQuestionList({ examId: item.id }, { responseType: 'blob' });
      let name=`${item.name}-答题明细.xlsx`
      this.downloadFile(res,name);
    },

    importStudent(item) {
      this.$router.push(`/admin/exam-manage/import-student/${item.id}`);
    },
    edit(item) {
      this.$router.push(`/admin/exam-manage/add-exam/${item.id}`);
    },
    view(item) {
      if (new Date().getTime() < new Date(item.startDttm).getTime()) {
        this.$message.error('考试暂未开始，请耐心等待');
        return;
      }
      this.$router.push(`/admin/exam-manage/exam-results/${item.id}`);
    },
    del(item) {
      this.$confirm('此操作将永久删除该考试, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let result = await this.Api.exammanage.deleteExam({ id: item.id })
        if (result.code != 200) {
          this.$message.error(result.message);
          return;
        }
        this.$message.success('删除成功');
        this.current=1;
        this.getList();

      }).catch(() => { }); // 取消不做任何操作  
    }
  },
};
</script>

<style scoped lang="scss">
.exam-list{
  overflow: hidden;
  width: 100%;
  .new-btn {
    width: 120px;
    // height: 32px;
    margin: 20px 0 0 20px;
    background: #3498db;
  }

  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }
  }

  .list {
    margin: 0 20px;
    overflow: hidden;
    padding: 0;

    li {
      height: 100px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      margin-bottom: 20px;
      list-style: none;
      box-sizing: border-box;

      .top,
      .bottom {
        display: flex;
        align-items: center;
        height: 50%;
        margin: 0 20px;
      }

      .top {
        justify-content: space-between;

        .left {
          width: 70%;

          .type {
            display: inline-block;
            width: 80px;
            height: 28px;
            line-height: 28px;
            border-radius: 5px;
            background: #f59a23;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-right: 20px;
          }
        }

        .right {
          width: 20%;
          display: flex;
          justify-content: center;

          a {
            color: #3bafda;
            margin-right: 30px;
          }
        }
      }

      .bottom {
        span {
          font-size: 14px;
          margin-left: 3px;
        }
      }
    }
  }

  .pagination {
    text-align: center;
  }
}
</style>
